import axios from 'axios';
import { protectedResources } from './../auth/azure-ad-b2c';
import { getToken, getTenantURL } from './apiToken';

export const downloadConsentForm = async (envelopeId, consentFormId) => {
    const accessToken = await getToken(protectedResources.apiDownloadConsentForm.scopes.read);
    const tetantID = getTenantURL();

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append('Authorization', bearer);

    const response = await axios({
        method: 'get',
        url: protectedResources.apiDownloadConsentForm.endpoint.replace('{envelopeId}', envelopeId).replace('{consentFormId}', consentFormId),
        headers: {
            Authorization: bearer,
            'x-tenantid': tetantID
        },
        responseType: 'blob'
    });

    return response.data;
};

export const acceptConsentForm = async (envelopeId, consentFormId) => {
    const accessToken = await getToken(protectedResources.apiAcceptConsentForm.scopes.read);
    const tetantID = getTenantURL();

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append('Authorization', bearer);

    const response = await axios({
        method: 'patch',
        url: protectedResources.apiAcceptConsentForm.endpoint.replace('{envelopeId}', envelopeId).replace('{consentFormId}', consentFormId),
        headers: {
            Authorization: bearer,
            'x-requestid': "2ff0e699-56cb-433d-88fe-4c173a0d47c5", // TODO: Add UUID
            'x-tenantid': tetantID
        },
        data: {}
    });

    return response.status === 200;
};