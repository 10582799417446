// MSAL imports
import { MsalProvider } from "@azure/msal-react";
// Material-UI imports
import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import './assets/fonts/montserrat/font-montserrat.css';
import { PageLayout } from "./components/layout/PageLayout";
import PageRouting from "./routes/routes";
import { CustomNavigationClient } from "./utils/NavigationClient";
import PdfViewerContextProvider from "./components/pdf-viewer/PdfViewerContextProvider";

function App({ pca }) {
    
    return (
        <ClientSideNavigation pca={pca}>
            <MsalProvider instance={pca}>
                <PdfViewerContextProvider>
                    <PageLayout>
                        <Container maxWidth="xl" disableGutters>
                            <PageRouting />
                        </Container>
                    </PageLayout>
                </PdfViewerContextProvider>
            </MsalProvider>
        </ClientSideNavigation>
    );
}

/**
 *  This component is optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
 */
function ClientSideNavigation({ pca, children }) {
    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    pca.setNavigationClient(navigationClient);

    // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
    const [firstRender, setFirstRender] = useState(true);
    useEffect(() => {
        setFirstRender(false);
    }, []);

    if (firstRender) {
        return null;
    }

    return children;
}

export default App;

