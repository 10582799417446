import { faAdd, faCheckCircle, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThumbUpAlt, ThumbUpOffAlt } from '@mui/icons-material';
import { Alert, Box, Button, Checkbox, Collapse, Divider, FormControl, FormControlLabel, FormGroup, Paper, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddEditArtifact from '../envelope-artifacts/AddEditArtifact';
import ArtifactsList from './../envelope-artifacts/ArtifactsList';

const rows = [
    {
        id: 101,
        title: 'Non-Disclosure Agreement',
        description: 'Please acknowledge and provide your consent on the NDA before signing document(s).',
        fileName: 'ConsentForm.pdf',
        status: 'Draft',
        createdOn: '11/18/2022'
    }
];

const AddEditConsentFormsList = () => {
    const theme = useTheme();

    const [showConsentAlert, setShowConsentAlert] = useState(true);
    const [addConsentForms, setAddConsentForms] = useState(false);
    const [consentForms, setConsentForms] = useState([]);

    const [openConsentFormEditor, setOpenConsentFormEditor] = useState(false);
    const [consentForm, setConsentForm] = useState({});

    const [informationStatus, setInformationStatus] = useState('default');
    useEffect(() => {
        if (addConsentForms && consentForms.length === 0) {
            setInformationStatus('warning');
        } else {
            setInformationStatus('success');
        }
    }, [addConsentForms, consentForms]);

    const handleAddNewConsentForm = () => {
        //let row = rows[0];
        //setConsentForms([...consentForms, row]);

        setConsentForm({});
        setOpenConsentFormEditor(true);
    }

    const handleEditConsentForm = (row) => {
        setConsentForm(row);
        setOpenConsentFormEditor(true);
    }

    const handleEditorSave = row => {
        let data = { ...row };

        if (!data.id) {
            data = {
                id: Math.floor(Math.random() * 10),
                status: 'Draft',
                ...data
            };

            setConsentForms([...consentForms, data]);
        } else {
            const index = consentForms.map(cf => cf.id).indexOf(data.id);
            let updatedConsentForms = [...consentForms];
            updatedConsentForms.splice(index, 1, data); //.filter(cf => cf.id !== data.id)

            setConsentForms(updatedConsentForms);
        }

        
        setOpenConsentFormEditor(false);
    }

    return (
        <Paper sx={{ my: 3, p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2 }}>
                    <FontAwesomeIcon icon={informationStatus === 'success' ? faCheckCircle : faWarning}
                        color={informationStatus === 'success' ? theme.palette.success.main : theme.palette.warning.main} fontSize={18} />
                    <Typography variant="subtitle2">CONSENT FORMS</Typography>
                </Box>
                <Button variant="outlined" color="primary" disabled={!addConsentForms} size="small" onClick={handleAddNewConsentForm} startIcon={<FontAwesomeIcon icon={faAdd} />}>ADD NEW</Button>
            </Box>
            <Divider sx={{ mt: 1.5 }} />
            <Collapse in={showConsentAlert} sx={{ my: showConsentAlert ? 2 : 0 }}>
                <Alert severity="info" onClose={() => { setShowConsentAlert(false) }}>
                    Consent forms need to be acknowledged prior to signing any documents and will be presented first.
                    Only recipients with <i>Signer</i> role will be presented with consent forms for acknowledgement.
                </Alert>
            </Collapse>
            {
                !addConsentForms ?
                    <FormControl component="fieldset" sx={{ mt: 0 }}>
                        <FormGroup row>
                            <FormControlLabel
                                control={<Checkbox checked={addConsentForms} onChange={() => setAddConsentForms(true)} icon={<ThumbUpOffAlt />} checkedIcon={<ThumbUpAlt />} />}
                                label="Do you wish to add any consent form(s) to the envelope?"
                                labelPlacement="start"
                            />
                        </FormGroup>
                    </FormControl> :
                    <ArtifactsList rows={consentForms} editHandler={handleEditConsentForm} />
            }
            <AddEditArtifact open={openConsentFormEditor} artifact={consentForm} saveHandler={handleEditorSave} />
        </Paper>
    );
};
export default AddEditConsentFormsList;
