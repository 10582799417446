import { Paper, useTheme } from '@mui/material';
import React, { useState } from 'react';
import AddEditArtifactsList from './../envelope-artifacts/AddEditArtifactsList';

const rows = [
    {
        id: 101,
        title: 'Non-Disclosure Agreement',
        description: 'Please acknowledge and provide your consent on the NDA before signing document(s).',
        fileName: 'ConsentForm.pdf',
        status: 'Draft',
        createdOn: '11/18/2022'
    }
];

const AddEditAttachmentsList = () => {
    const theme = useTheme();

    const [attachments, setAttachments] = useState([]);

    const saveHandler = (artifacts) => {
        setAttachments(artifacts);
    }

    return (
        <AddEditArtifactsList title={"ATTACHMENTS"} artifacts={attachments} saveHandler={saveHandler} alert={<>Attachments are reference documents that you may want to include in the envelope.
            These are reference only documents and do not need any action from the signers.
        </>} alertSeverity="info" />
    );
};
export default AddEditAttachmentsList;
