import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import React from 'react';

const Logout = () => {

    return (
        <Box sx={{ position: 'absolute', top: '20%', left: '35%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2 }}>
                <FontAwesomeIcon icon={faCheckCircle} color='green' fontSize='48px' />
                <Typography variant='h5'>You have successfully logged out!</Typography>
            </Box>
            
        </Box>
    );
};
export default Logout;
