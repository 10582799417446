import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from 'react';
import { downloadConsentForm } from './../../../api/consentFormApi';
import { useParams } from 'react-router-dom';
import ProgressBackdrop from '../../../components/progress-backdrop/ProgressBackdrop';
import { acceptConsentForm } from './../../../api/consentFormApi';
import { fetchEnvelope, recipientCard } from './envelopeSlice';
import { useDispatch } from 'react-redux';
//import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ConsentForm = ({ form, consentHandler }) => {
   const theme = useTheme();
   const { id: envelopeId } = useParams();
   const dispatch = useDispatch();

   const [consentFormBlob, setConsentFormBlob] = useState(null);

   useEffect(() => {
      dispatch(recipientCard({ payload: false }))
      return () => false
   }, [])

   useEffect(() => {
      (async () => {
         const blob = await downloadConsentForm(envelopeId, form.id);
         setConsentFormBlob(blob);
      })();
   }, []);

   const descriptionElementRef = useRef(null);
   useEffect(() => {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
         descriptionElement.focus();
      }
   });

   const handleClose = () => {
      consentHandler();
   }

   const handleAcceptConsentForm = async () => {
      const result = await acceptConsentForm(envelopeId, form.id);

      console.log(result);
      if (result)
         await dispatch(fetchEnvelope(envelopeId));
      consentHandler();
   }

   const [numPages, setNumPages] = useState(0);
   const onDocumentLoadSuccess = ({ numPages }) => {
      setNumPages(numPages);
   }

   if (!consentFormBlob) {
      return <ProgressBackdrop open={true} />;
   }

   return (
      <Dialog open={true} onClose={consentHandler} scroll="paper" maxWidth="xl">
         <DialogTitle>{form.title}</DialogTitle>
         <DialogContent dividers={true}>
            <DialogContentText ref={descriptionElementRef} tabIndex={-1}>
               <Document file={consentFormBlob} onLoadSuccess={onDocumentLoadSuccess}>
                  {
                     [...new Array(numPages)].map((p, i) => <Page key={i} pageNumber={i + 1} />)
                  }
                  <Page pageNumber={1} />
               </Document>
               {/*{*/}
               {/*    [...new Array(50)].map(*/}
               {/*        () => `In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document */}
               {/*        or a typeface without relying on meaningful content.Lorem ipsum may be used as a placeholder before final copy is available.`*/}
               {/*    ).join('\n')*/}
               {/*}*/}
            </DialogContentText>
         </DialogContent>
         <DialogActions>
            <Button onClick={handleAcceptConsentForm} disabled={form.consent?.statusName === 'Consented'}>Accept</Button>
            <Button onClick={handleClose}>Decline</Button>
            <Button onClick={handleClose}>Cancel</Button>
         </DialogActions>
      </Dialog>
   );
}

export default ConsentForm;
