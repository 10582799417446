import React, { useState } from 'react';
import PdfViewerContext from './PdfViewerContext';

const PdfViewerContextProvider = (props) => {

    const [instance, setInstance] = useState();
    const [annotManager, setAnnotManager] = useState();

    return (
        <PdfViewerContext.Provider value={{ instance, setInstance, annotManager, setAnnotManager }}>
            {props.children}
        </PdfViewerContext.Provider>
    );
}

export default PdfViewerContextProvider;
