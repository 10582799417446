import { AuthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar as MuiAppBar, Box, Stack } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Link as RouterLink } from "react-router-dom";
import { BrandLogo } from "../branding/BrandLogo";
import shLogo from './../../assets/images/eProviderSync.png'; // BCBSNE6Blue.png eProviderSync.png
import { DrawerHeader, drawerWidth } from './DrawerStyled';
import NavDrawer from './NavDrawer';
import NavMenu from "./NavMenu";
import NavProfile from "./NavProfile";


const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

function renderLogo() {
    return (
        //<Stack
        //    component={RouterLink}
        //    to="/"
        //    sx={{ mr: { xs: 0, md: 1 }, display: 'flex', flexGrow: 1, alignItems: "flex-start", textDecoration: "none" }}
        //>
        //    <Box
        //        component="img"
        //        sx={{
        //            height: { xs: '1rem', md: '1rem' }, mr: { xs: 0, md: 1 }, objectFit: "scale-down",
        //            marginLeft: "1rem"
        //        }}
        //        alt="Collateral"
        //        src={shLogo}
        //    />
        //    <Typography variant="subtitle2" noWrap sx={{
        //        padding: "0.25rem 0.95rem",
        //        fontWeight: "bold",
        //        background: "#F8D164",
        //        borderRadius: "0.25rem",
        //        fontSize: "11px",
        //        fontColor: "#483d8b",
        //        marginLeft: "1rem"
        //    }}> .Collaborate</Typography>
        //</Stack>
        <Box component={RouterLink} to="/" sx={{ textDecoration: 'none' }}>
            <BrandLogo />
        </Box>
    );
}

export default function NavBarWithDrawer(props) {
    const theme = useTheme();
    const isAuthenticated = useIsAuthenticated();

    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" color="inherit" open={open} elevation={1}>
                <Toolbar sx={{ paddingLeft: open ? '0px !important' : 2 }}>
                    <AuthenticatedTemplate>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 2,
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </AuthenticatedTemplate>
                    <Box component="div" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <Box>
                            {renderLogo()}
                        </Box>
                        <Box sx={{ display: "flex" }}>
                            <NavMenu />
                            {isAuthenticated && <NavProfile />}
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <AuthenticatedTemplate>
                <NavDrawer open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} />
            </AuthenticatedTemplate>
            <Box component="main" sx={{ flexGrow: 1 }}>
                <DrawerHeader />
                {props.children}
            </Box>
        </Box>
    );
}