import { faClock, faSignature } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Paper } from '@mui/material';
import { Alert } from '@mui/material';
import { Box, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { orange } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import InteractionStatusIcon from '../../../components/interaction-status/InteractionStatusIcon';
import ConsentFormsList from './ConsentFormsList';
import { recipientCard, selectHasLoggedInUserConsentedAllForms } from './envelopeSlice';


function GetDocumentStatusIcon(consentForm) {
   // TODO: Until ConsentStatus is added on ConsentForm
   return (
      <FontAwesomeIcon icon={faClock} size="xl" color={orange[500]} />
   );
}

const DocumentsList = () => {
   const theme = useTheme();
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const documents = useSelector((state) => state.envelope.entity?.documents || []);
   const hasConsentedAllForms = useSelector(selectHasLoggedInUserConsentedAllForms);

   useEffect(() => {
      dispatch(recipientCard({ payload: false }))
      return () => false
   }, [])

   if (!documents || documents.length === 0) {
      return <></>;
   }

   const openDocument = d => {
      console.log(d, "clicked")
      navigate(`${d.id}`)
   }

   return (
      <>
         {
            !hasConsentedAllForms && (
               <Box mb={2}><ConsentFormsList /></Box>
            )
         }
         <Paper sx={{ p: 2 }}>
            <Typography sx={{ color: "text.secondary", fontSize: 12, fontWeight: 700 }}>DOCUMENTS</Typography>
            <Typography variant="body2" sx={{ py: 1 }}>You are requested to sign the below documents.</Typography>

            <List dense>
               {
                  documents.map(c => (
                     <ListItem key={c.id} disablePadding secondaryAction={
                        <Button variant="outlined" color="secondary" sx={{ minWidth: 160 }}
                           startIcon={<FontAwesomeIcon icon={faSignature} />} disabled={!hasConsentedAllForms} onClick={() => openDocument(c)} > REVIEW & SIGN</Button>
                     }>
                        <ListItemButton onClick={() => openDocument(c)} disabled={!hasConsentedAllForms}>
                           <ListItemIcon sx={{ minWidth: 24, mr: 1 }} >
                              <InteractionStatusIcon status={c.status} />
                           </ListItemIcon>
                           <ListItemText primary={c.title} sx={{ fontWeight: 'bold' }} secondary={c.description} />
                        </ListItemButton>
                     </ListItem>))
               }
            </List>
         </Paper>
      </>
   );
}

export default DocumentsList;
