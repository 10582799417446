import { AuthenticatedTemplate } from '@azure/msal-react';
import { faAdd, faBriefcase, faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '../../components/layout/PageTitle';
import WorkspaceItems from '../../features/signature/workspace-items/WorkspaceItems';

const Workspace = () => {
    const navigate = useNavigate();

    const handleAddNewEnvelope = () => {
        navigate('envelopes/new');
    }

    return (
        <AuthenticatedTemplate>
            <Box px={3} pb={3} pt={2}>
                <PageTitle title="Workspace" faIcon={faBriefcase} helperText="Below envelopes are routed to you for action. Click on envelope to open."
                    rightAction={<Button variant="contained" color="secondary" startIcon={<FontAwesomeIcon icon={faAdd} />} onClick={handleAddNewEnvelope}>NEW ENVELOPE</Button>}
                />

                <WorkspaceItems />

            </Box>
        </AuthenticatedTemplate>
    );
};
export default Workspace;
