import axios from 'axios';
import { protectedResources } from './../auth/azure-ad-b2c';
import { getToken, getTenantURL } from './apiToken';


export const getInboxEnvelopes = async () => {
   const accessToken = await getToken(protectedResources.apiInboxEnvelopes.scopes.read);
   const tetantID = getTenantURL();
   console.log("🚀 ~ file: envelopeApi.js:9 ~ getInboxEnvelopes ~ tetantID:", tetantID)

   const headers = new Headers();
   const bearer = `Bearer ${accessToken}`;

   headers.append('Authorization', bearer);

   const response = await axios({
      method: 'get',
      url: protectedResources.apiInboxEnvelopes.endpoint,
      headers: {
         Authorization: bearer,
         'x-tenantid': tetantID
      }
   });

   return response.data;
};

export const getEnvelope = async (id) => {
   const accessToken = await getToken(protectedResources.apiEnvelope.scopes.read);
   const tetantID = getTenantURL();

   const headers = new Headers();
   const bearer = `Bearer ${accessToken}`;

   headers.append('Authorization', bearer);

   const response = await axios({
      method: 'get',
      url: protectedResources.apiEnvelope.endpoint + `/${id}`,
      headers: {
         Authorization: bearer,
         'x-tenantid': tetantID
      }
   });

   return response.data;
};

export const getEnvelopeStatusCount = async () => {
   const accessToken = await getToken(protectedResources.apiEnvelopeStatusCount.scopes.read);
   const tetantID = getTenantURL();


   const headers = new Headers();
   const bearer = `Bearer ${accessToken}`;

   headers.append('Authorization', bearer);

   const response = await axios({
      method: 'get',
      url: protectedResources.apiEnvelopeStatusCount.endpoint,
      headers: {
         Authorization: bearer,
         'x-tenantid': tetantID
      }
   });

   return response.data;
};