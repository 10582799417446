import { faAdd, faCheckCircle, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThumbUpAlt, ThumbUpOffAlt } from '@mui/icons-material';
import { Alert, Box, Button, Checkbox, Collapse, Divider, FormControl, FormControlLabel, FormGroup, Paper, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddEditArtifact from '../envelope-artifacts/AddEditArtifact';
import ArtifactsList from './../envelope-artifacts/ArtifactsList';

const AddEditArtifactsList = ({ title, 
    artifacts, saveHandler, optional = true,
    alert, alertDismissable = false, alertSeverity = "info" }) => {
    const theme = useTheme();

    const [showAlert, setShowAlert] = useState(!!alert);
    const [allowAddNew, setAllowAddNew] = useState(!optional);

    const [openArtifactEditor, setOpenArtifactEditor] = useState(false);
    const [artifact, setArtifact] = useState({});

    const [status, setStatus] = useState('default');
    useEffect(() => {
        if (!optional && artifacts.length === 0) {
            setStatus('warning');
        } else {
            setStatus('success');
        }
    }, [artifacts]);

    const handleAddNewArtifact = () => {
        setArtifact({});
        setOpenArtifactEditor(true);
    }

    const handleEditArtifact = (row) => {
        setArtifact(row);
        setOpenArtifactEditor(true);
    }

    const handleEditorSave = row => {
        let data = { ...row };
        let updatedArtifacts = [];

        if (!data.id) {
            data = {
                id: Math.floor(Math.random() * 10),
                status: 'Draft',
                ...data
            };

            updatedArtifacts = [...artifacts, data];
        } else {
            const index = artifacts.map(a => a.id).indexOf(data.id);
            let clonedAndSplicedArtifacts = [...artifacts];
            clonedAndSplicedArtifacts.splice(index, 1, data);

            updatedArtifacts = [...clonedAndSplicedArtifacts];
        }

        saveHandler(updatedArtifacts);
        setOpenArtifactEditor(false);
    }

    return (
        <Paper sx={{ my: 3, p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2 }}>
                    <FontAwesomeIcon icon={status === 'success' ? faCheckCircle : faWarning}
                        color={status === 'success' ? theme.palette.success.main : theme.palette.warning.main} fontSize={18} />
                    <Typography variant="subtitle2">{title}</Typography>
                </Box>
                <Button variant="outlined" color="primary" disabled={!allowAddNew} size="small" onClick={handleAddNewArtifact} startIcon={<FontAwesomeIcon icon={faAdd} />}>ADD NEW</Button>
            </Box>
            <Divider sx={{ mt: 1.5 }} />
            <Collapse in={showAlert} sx={{ my: showAlert ? 2 : 0 }}>
                <Alert severity={alertSeverity} onClose={() => { alertDismissable ? setShowAlert(false) : setShowAlert(true) }}>
                    {alert}
                </Alert>
            </Collapse>
            {
                (optional && !allowAddNew) ?
                    <FormControl component="fieldset" sx={{ mt: 0 }}>
                        <FormGroup row>
                            <FormControlLabel
                                control={<Checkbox checked={false} onChange={() => setAllowAddNew(true)} icon={<ThumbUpOffAlt />} checkedIcon={<ThumbUpAlt />} />}
                                label={ `Do you wish to add any ${title.toLowerCase()} to the envelope?` }
                                labelPlacement="start"
                            />
                        </FormGroup>
                    </FormControl> :
                    <ArtifactsList rows={artifacts} editHandler={handleEditArtifact} />
            }
            <AddEditArtifact open={openArtifactEditor} artifact={artifact} saveHandler={handleEditorSave} />
        </Paper>
    );
};
export default AddEditArtifactsList;
