import axios from 'axios';
import { protectedResources } from './../auth/azure-ad-b2c';
import { getToken, getTenantURL } from './apiToken';

export const downloadDocument = async (envelopeId, documentId) => {
   const accessToken = await getToken(protectedResources.apiDownloadDocument.scopes.read);
   const tetantID = getTenantURL();

   const headers = new Headers();
   const bearer = `Bearer ${accessToken}`;

   headers.append('Authorization', bearer);

   const response = await axios({
      method: 'get',
      url: protectedResources.apiDownloadDocument.endpoint.replace('{envelopeId}', envelopeId).replace('{documentId}', documentId),
      headers: {
         Authorization: bearer,
         'x-tenantid': tetantID
      },
      responseType: 'blob'
   });

   return response.data;
};

export const vaidateSignatureRequest = async (envelopeId) => {
   const accessToken = await getToken(protectedResources.apiVaidateSignatureRequest.scopes.read);
   const tetantID = getTenantURL();

   const headers = new Headers();
   const bearer = `Bearer ${accessToken}`;

   headers.append('Authorization', bearer);


   const response = await axios({
      method: 'get',
      url: protectedResources.apiVaidateSignatureRequest.endpoint.replace('{envelopId}', envelopeId),
      headers: {
         Authorization: bearer,
         'x-tenantid': tetantID
      }
   });

   return response.data;
};

export const signDocument = async (envelopeId, documentId, blob, comments = "Signed document.") => {
   const accessToken = await getToken(protectedResources.apiSignDocument.scopes.sign);
   const tetantID = getTenantURL();

   const headers = new Headers();
   const bearer = `Bearer ${accessToken}`;

   headers.append('Authorization', bearer);

   var data = {
      envelopeId,
      documentId,
      comments
   }

   var formdata = new FormData();
   formdata.append('content', JSON.stringify(data));
   formdata.append('file', blob);

   const response = await axios({
      method: 'post',
      url: protectedResources.apiSignDocument.endpoint.replace('{envelopeId}', envelopeId).replace('{documentId}', documentId),
      headers: {
         Authorization: bearer,
         'x-requestid': "2ff0e699-56cb-433d-88fe-4c173a0d47c5", // TODO: Add UUID
         'x-tenantid': tetantID
      },
      data: formdata
   });

   return response.data;
};

export const rejectDocument = async (envelopeId, documentId, rejectMsg) => {
   const accessToken = await getToken(protectedResources.apiAbandonEnvelope.scopes.read);
   const tetantID = getTenantURL();

   const headers = new Headers();
   const bearer = `Bearer ${accessToken}`;

   headers.append('Authorization', bearer);

   var data = {
      reason: rejectMsg
   }

   const response = await axios({
      method: 'post',
      url: protectedResources.apiAbandonEnvelope.endpoint.replace(`{envelopId}`, envelopeId).replace(`{documentId}`, documentId),
      headers: {
         Authorization: bearer,
         'x-requestid': "2ff0e699-56cb-433d-88fe-4c173a0d47c5", // TODO: Add UUID
         'x-tenantid': tetantID,
         'Content-Type': 'application/json'
      },
      data: JSON.stringify(data)
   });

   return response.data;
};
