import { faAward, faCertificate, faCheck, faCheckCircle, faCheckDouble, faCheckToSlot, faClock, faCross, faEllipsis, faFileCircleCheck, faFileCircleExclamation, faFileCircleXmark, faFileShield, faFileSignature, faRemove, faSign, faSignature } from "@fortawesome/free-solid-svg-icons";
import { green } from "@mui/material/colors";
import { red, blueGrey, orange } from "@mui/material/colors";

export const InteractionStatusSettings = {
    Draft: {
        id: 1,
        desc: 'Draft',
        color: blueGrey[200],
        icon: faClock
    },
    Ready: {
        id: 2,
        desc: 'Ready',
        color: blueGrey[400],
        icon: faFileSignature
    },
    Signed: {
        id: 3,
        desc: 'Signed',
        color: green[600],
        icon: faFileShield
    },
    Completed: {
        id: 4,
        desc: 'Completed',
        color: green[600],
        icon: faFileCircleCheck
    },
    Declined: {
        id: 5,
        desc: 'Declined',
        color: orange[400],
        icon: faFileCircleExclamation
    },
    Errored: {
        id: 6,
        desc: 'Errored',
        color: red[500],
        icon: faFileCircleXmark
    },
    Canceled: {
        id: 7,
        desc: 'Canceled',
        color: 'lightgreen',
        icon: faClock
    },
    Invalidated: {
        id: 8,
        desc: 'Invalidated',
        color: 'lightgreen',
        icon: faClock
    },
    Expired: {
        id: 9,
        desc: 'Expired',
        color: 'lightgreen',
        icon: faClock
    },
    AwaitingConsent: {
        id: 10,
        desc: 'Awaiting Consent',
        color: 'lightgreen',
        icon: faClock
    },
    Consented: {
        id: 11,
        desc: 'Consented',
        color: 'lightgreen',
        icon: faClock
    },
    ConsentRejected: {
        id: 12,
        desc: 'Consent Rejected',
        color: 'lightgreen',
        icon: faClock
    }
}

export function getInteractionStatusColor(status) {
    return InteractionStatusSettings[status]?.color || '#ccc';
};

export function getInteractionStatusIcon(status) {
    return InteractionStatusSettings[status]?.icon || faClock;
}

export function getInteractionStatusDescription(status) {
    return InteractionStatusSettings[status]?.desc || 'Unknown';
}