import NavBar from './../nav/NavBar';
import NavBarWithDrawer from './../nav/NavBarWithDrawer';

export const PageLayout = (props) => {
    return (
        <>
            <NavBar>
                {props.children}
            </NavBar>
        </>
    );
};