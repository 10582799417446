// MSAL imports
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from "react-router-dom";
import { validateAndAcquireIfRequired } from "./api/apiToken";
import App from './App';
import { store } from './app/store';
import { theme } from "./assets/styles/theme";
import { loginRequest, msalConfig } from "./auth/azure-ad-b2c";
import './index.css';
import reportWebVitals from './reportWebVitals';

export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

/**
* Event handler function which allows users to fire events after the PublicClientApplication object
* has loaded during redirect flows. This should be invoked on all page loads involved in redirect
* auth flows.
*/
msalInstance.handleRedirectPromise()
    .then((tokenResponse) => {
        // AuthenticationResult (i.e., tokenResponse) is supplied here only upon login.
        // If null, assume existing token was picked from storage.
        if (!tokenResponse) {
            if (msalInstance.getActiveAccount()) {
                // Token is present, but it may be expired. Ensure token is valid and if expired, reissue.
                (async () => await validateAndAcquireIfRequired(loginRequest.scopes))();
            } else {
                // No user has signed in, auto-sign if not on logout page.
                if (window.location.pathname !== '/logout') {
                    msalInstance.loginRedirect(loginRequest);
                }
            }
        }
    })
    .catch((error) => {
        console.log('handleRedirectPromise:error', error);
    });

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        console.log('Logged in!');
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    } else if (event.eventType === EventType.LOGOUT_SUCCESS) {
        console.log('Logged out!');
    }
});

const container = document.getElementById('root') || document.createElement('div');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Router>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <App pca={msalInstance} />
                </ThemeProvider>
            </Provider>
        </Router>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
