import * as React from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

export const DialogTitleCustom = (props) => {
    const handleClose = () => {
        props.onClose(props.type)
    }
    return (
        <DialogTitle sx={{ m: 0, py: 1.5, paddingLeft: 2 }} style={{ backgroundColor: props?.title ? "#0A3F5A" : "none", color: "white", fontWeight: 500 }}>
            <Typography variant='h4'>{props.title}</Typography>
            <IconButton
                aria-label="close"
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 1,
                    color: props?.title ? "white" : "navy", m: 0, p: 1,
                }}
                onClick={handleClose}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
    );
}


export const DialogContentCustom = (props) => {
    return (
        <DialogContent className='modal-content'>
            <DialogContentText>
                {props.children}
            </DialogContentText>
        </DialogContent>
    );
}


export const DialogActionCustom = (props) => {
    return (
        <DialogActions>
            {props.children}
        </DialogActions>
    );
}
