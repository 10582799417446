import { configureStore } from '@reduxjs/toolkit';
import InboxReducer from './../features/signature/inbox/inboxSlice';
import EnvelopeReducer from './../features/signature/envelope/envelopeSlice';

export const store = configureStore({
    reducer: {
        inbox: InboxReducer,
        envelope: EnvelopeReducer
    },
});
