import { useIsAuthenticated } from "@azure/msal-react";
import { AppBar as MuiAppBar, Box, Container, CssBaseline, Divider, Stack, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from "react-router-dom";
import { BrandLogo } from "../branding/BrandLogo";
import shLogo from './../../assets/images/eProviderSync.png'; // BCBSNE6Blue.png eProviderSync.png
import { DrawerHeader } from "./DrawerStyled";
import NavMenu from "./NavMenu";
import NavProfile from "./NavProfile";

function renderLogo() {
   return (
      <Box component={RouterLink} to="/" sx={{ textDecoration: 'none', width: '100%' }}>
         <BrandLogo />
      </Box>
   );
}

const AppBar = (props) => {
   const isAuthenticated = useIsAuthenticated();

   return (
      <Box>
         <CssBaseline />
         <MuiAppBar position="fixed" color="primary" elevation={0} sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
            <Container maxWidth="xl" disableGutters>
               <Toolbar disableGutters sx={{ alignItems: 'stretch' }}>
                  <Box component="div" sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: 'stretch' }}>
                     <Box sx={{ background: "#fff", padding: "9px 45px 9px 0px", clipPath: "polygon(0% 0%, 85% 0%, 100% 100%, 100% 100%, 0% 100%)", width: 304, display: 'flex', alignItems: 'center' }}>
                        {renderLogo()}
                     </Box>
                     <Box sx={{ display: "flex", paddingRight: "1rem", alignItems: "center" }}>
                        <NavMenu />
                        {isAuthenticated && <NavProfile />}
                     </Box>
                  </Box>
               </Toolbar>
            </Container>
            <Divider sx={{
               display: "grid", placeItems: "center", borderBottom: "0.25rem solid",
               borderImage: "linear-gradient(to left, turquoise, greenyellow) 1 0"
            }} />
         </MuiAppBar>
         <Box component="main" sx={{ flexGrow: 1 }}>
            <DrawerHeader />
            {props.children}
         </Box>
      </Box>
   );
};
export default AppBar;
