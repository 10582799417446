import { faDeleteLeft, faDownload, faEdit, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FiberManualRecord } from '@mui/icons-material';
import { Box, IconButton, LinearProgress, Paper, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import * as moment from 'moment';
import React, { useState } from 'react';
import { CustomNoRowsOverlay } from '../../../../components/grid-overlay/GridOverlay';

function generateColumns(extraColumns, editHandler) {
    return [
        { field: 'id', headerName: 'Id', minWidth: 60, flex: 1 },
        {
            field: 'title', headerName: 'Title', minWidth: 300, flex: 4,
            renderCell: (params) => (
                <Typography variant="subtitle" color="text.primary" sx={{ fontWeight: '700' }}>{params.value}</Typography>
            )
        },
        { field: 'description', headerName: 'Description', minWidth: 80, flex: 6 },
        //{ field: 'fileName', headerName: 'File', minWidth: 80, flex: 3 },
        {
            field: 'fileName', headerName: 'File', sortable: false, minWidth: 80, flex: 4,
            renderCell: (params) => {
                const downloadFile = e => {
                    e.stopPropagation(); // Don't select this row after clicking
                    console.log('downloadFile', params);
                };

                const previewFile = e => {
                    e.stopPropagation(); // Don't select this row after clicking
                    console.log('previewFile', params);
                };

                return (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <Typography>{params.value}</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton onClick={previewFile} color="info">
                                <FontAwesomeIcon icon={faMagnifyingGlass} fontSize={16} />
                            </IconButton>
                            <IconButton onClick={downloadFile} color="info">
                                <FontAwesomeIcon icon={faDownload} fontSize={16} />
                            </IconButton>
                        </Box>
                    </Box>
                );
            }
        },
        {
            field: 'status', headerName: 'Status', minWidth: 180, flex: 2,
            renderCell: (params) => {
                const isComplete = params.value === 'Ready';

                return <Box sx={{ display: 'flex' }}>
                    <FiberManualRecord fontSize='small' sx={{ mr: 1, color: isComplete ? '#4caf50' : '#ccc' }} />
                    {params.value}
                </Box>
            }
        },
        {
            field: 'createdOn', headerName: 'Requested Date', minWidth: 120, flex: 2,
            renderCell: (params) => (
                <Typography variant="subtitle" color="text.primary">{moment(params.value).format("MMM DD, YYYY")}</Typography>
            )
        },
        ...extraColumns, // Extra columns injected right before the action buttons.
        {
            field: 'action', headerName: 'Actions', sortable: false, minWidth: 120, flex: 2,
            renderCell: (params) => {
                const editRow = e => {
                    e.stopPropagation(); // Don't select this row after clicking
                    editHandler(params.row);
                };

                const deleteRow = e => {
                    e.stopPropagation(); // Don't select this row after clicking
                    console.log('deleteRow', params);
                };

                return (
                    <Box sx={{ display: 'flex' }}>
                        <IconButton onClick={editRow} color="primary">
                            <FontAwesomeIcon icon={faEdit} fontSize={16} />
                        </IconButton>
                        <IconButton onClick={deleteRow} color="error">
                            <FontAwesomeIcon icon={faDeleteLeft} fontSize={16} />
                        </IconButton>
                    </Box>
                );
            }
        }
    ];
}

const ArtifactsList = ({ rows = [], extraColumns = [], editHandler}) => {

    const [columns] = useState(generateColumns(extraColumns, editHandler));

    return (
        <Paper sx={{ mt: 2, height: (112 + (rows.length === 0 ? 144 : (rows.length * 52))), width: '100%' }}>
            <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataGrid
                        autoheight
                        components={{
                            NoRowsOverlay: CustomNoRowsOverlay,
                            LoadingOverlay: LinearProgress
                        }}
                        loading={false}
                        rows={rows}
                        columns={columns}
                        onRowClick={() => { }}
                    />
                </div>
            </div>
        </Paper>
    );
};
export default ArtifactsList;
