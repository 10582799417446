import { LogLevel } from "@azure/msal-browser";

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
const eSign_UserFlow = process.env.REACT_APP_eSign_UserFlow || "B2C_1_SH_Collaborate_Signup_Signin"
const eSign_Domain = process.env.REACT_APP_eSign_Domain || "eprovidersync.b2clogin.com"
const eSign_UI_ClientId = process.env.REACT_APP_eSignUI_ClientId || "d8d8d951-2665-4ce5-a846-ae1ff17598cf"
const eSignAPI_ClientId = process.env.REACT_APP_eSignAPI_ClientId || "60242f8a-653c-4364-8ac8-7aaae90944a7"

const eSign_Authority =
   `https://${eSign_Domain}/${eSign_Domain.split(".")[0]
   }.onmicrosoft.com/${eSign_UserFlow}` ||
   "https://eprovidersync.b2clogin.com/eprovidersync.onmicrosoft.com/B2C_1_SH_Collaborate_Signup_Signin";
export const b2cPolicies = {
   names: {
      signUpSignIn: eSign_UserFlow,
      // forgotPassword: "b2c_1_reset",
      // editProfile: "b2c_1_edit_profile"
   },
   authorities: {
      signUpSignIn: {
         authority: eSign_Authority,
      },
      // forgotPassword: {
      //     authority: "https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/b2c_1_reset",
      // },
      // editProfile: {
      //     authority: "https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/b2c_1_edit_profile"
      // }
   },
   authorityDomain: eSign_Domain,
};

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
export const msalConfig = {
   auth: {
      clientId: eSign_UI_ClientId, // This is the ONLY mandatory field that you need to supply.
      authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
      knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
      redirectUri: "/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
      postLogoutRedirectUri: "/logout", // Indicates the page to navigate after logout.
      navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
   },
   cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: isIE || isEdge || isFirefox,
   },
   system: {
      loggerOptions: {
         loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
               return;
            }
            switch (level) {
               case LogLevel.Error:
                  console.error(message);
                  return;
               case LogLevel.Info:
                  console.info(message);
                  return;
               case LogLevel.Verbose:
                  console.debug(message);
                  return;
               case LogLevel.Warning:
                  console.warn(message);
                  return;
               default:
                  return;
            }
         },
      },
   },
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
console.log("Environment vars", process.env);
const eSignApiBaseUri =
   process.env.REACT_APP_ESIGN_API_BASE_URI ||
   "https://simplify-esign-api-dev.azurewebsites.net";
const scope_value = `https://${msalConfig?.auth.knownAuthorities[0].split(".")[0]
   }.onmicrosoft.com`;
export const protectedResources = {
   apiInboxEnvelopes: {
      endpoint: `${eSignApiBaseUri}/api/v1/signature/envelopes`,
      scopes: {
         read: [
            `${scope_value}/${eSignAPI_ClientId}/Envelope.Add`,
         ],
         sign: [
            `${scope_value}/${eSignAPI_ClientId}/Document.Sign`,
         ],
         negotiate: [
            `${scope_value}/${eSignAPI_ClientId}/Collateral.Negotiate`,
         ],
      },
   },

   apiEnvelope: {
      endpoint: `${eSignApiBaseUri}/api/v1/signature/envelopes`,
      scopes: {
         read: [
            `${scope_value}/${eSignAPI_ClientId}/Envelope.Add`,
         ],
      },
   },
   apiDownloadDocument: {
      endpoint: `${eSignApiBaseUri}/api/v1/signature/envelopes/{envelopeId}/documents/{documentId}/download`,
      scopes: {
         read: [
            `${scope_value}/${eSignAPI_ClientId}/Envelope.Add`,
         ],
      },
   },
   apiSignDocument: {
      endpoint: `${eSignApiBaseUri}/api/v1/signature/envelopes/{envelopeId}/documents/{documentId}/sign`,
      scopes: {
         sign: [
            `${scope_value}/${eSignAPI_ClientId}/Document.Sign`,
         ],
      },
   },
   apiDownloadConsentForm: {
      endpoint: `${eSignApiBaseUri}/api/v1/signature/envelopes/{envelopeId}/consentforms/{consentFormId}/download`,
      scopes: {
         read: [
            `${scope_value}/${eSignAPI_ClientId}/Envelope.Add`,
         ],
      },
   },
   apiAcceptConsentForm: {
      endpoint: `${eSignApiBaseUri}/api/v1/signature/envelopes/{envelopeId}/consentforms/{consentFormId}/accept`,
      scopes: {
         read: [
            `${scope_value}/${eSignAPI_ClientId}/Envelope.Add`,
         ],
      },
   },
   apiVaidateSignatureRequest: {
      endpoint: `${eSignApiBaseUri}/api/v1/Signature/envelopes/{envelopId}/vaidateSignatureRequest`,
      scopes: {
         read: [
            `${scope_value}/${eSignAPI_ClientId}/Envelope.Add`,
         ],
      },
   },
   apiAbandonEnvelope: {
      endpoint: `${eSignApiBaseUri}/api/v1/Signature/envelopes/{envelopId}/documents/{documentId}/reject`,
      scopes: {
         read: [
            `${scope_value}/${eSignAPI_ClientId}/Envelope.Add`,
         ],
      },
   },
   apiEnvelopeStatusCount: {
      endpoint: `${eSignApiBaseUri}/api/v1/Signature/envelopeStatusCount`,
      scopes: {
         read: [
            `${scope_value}/${eSignAPI_ClientId}/Envelope.Add`,
         ],
      },
   },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
   scopes: [
      ...protectedResources.apiInboxEnvelopes.scopes.read,
      ...protectedResources.apiInboxEnvelopes.scopes.sign,
      ...protectedResources.apiInboxEnvelopes.scopes.negotiate,
   ],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
   graphMeEndpoint: "https://localhost:7071/api/Account", //https://graph.microsoft-ppe.com/v1.0/me
};
