import { faClock, faFileCircleCheck, faFileContract, faFileImport, faFileSignature, faFileUpload, faMailBulk, faMessage, faPaperclip, faTimeline, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Button, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Table, TableBody, TableCell, TableContainer, TableRow, Toolbar, Typography } from '@mui/material';
import { green, blue } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import * as moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import InteractionStatus from '../../../components/interaction-status/InteractionStatus';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useMediaQuery } from '@mui/material';
import { Close, Delete, Remove } from '@mui/icons-material';
import { fetchValidate, recipientCard } from './envelopeSlice';
import { selectAll } from '../inbox/inboxSlice';
import { refreshInbox } from './../inbox/inboxSlice';

const maxDrawerWidth = 304;

const DrawerContent = ({ envelope, isSmallViewport, toggleDrawer }) => {
   const theme = useTheme();
   const navigate = useNavigate();
   const envelopeId = useParams();


   const { id: docId } = useParams();
   const envelopes = useSelector(selectAll);
   const documentStatus = envelopes.filter(item => item.id == docId);


   const dispatch = useDispatch();

   const handlerClick = () => {
      dispatch(recipientCard({ payload: true }))
      navigate('/envelopes/' + envelopeId.id);
   }

   useEffect(() => {
      try {
         dispatch(refreshInbox());
      } catch (err) {
         console.log(err.message);
      }
      return () => false
   }, [])
   return (
      <Box sx={{ overflow: 'auto', p: 1 }}>
         {/*<Typography px={2} py={2} variant="h6">Envelope</Typography>*/}
         <Box p={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Button startIcon={<FontAwesomeIcon icon={faMailBulk} />} size="large" sx={{ fontSize: '1rem' }} component={RouterLink} to={""}>Envelope</Button>
            {isSmallViewport && <IconButton onClick={toggleDrawer}><Close /></IconButton>}
         </Box>
         <TableContainer>
            <Table size="small">
               <TableBody>
                  <TableRow>
                     <TableCell>Version</TableCell>
                     <TableCell>1.0</TableCell>
                  </TableRow>
                  <TableRow>
                     <TableCell>Status</TableCell>
                     <TableCell><InteractionStatus status={documentStatus[0]?.status} /></TableCell>
                  </TableRow>
                  <TableRow>
                     <TableCell>Requested By</TableCell>
                     <TableCell>{envelope?.ownerName}</TableCell>
                  </TableRow>
                  <TableRow>
                     <TableCell>Requested On</TableCell>
                     <TableCell>{envelope?.updatedOn ? moment(envelope?.updatedOn).format("MMM Do, YYYY") : ''}</TableCell>
                  </TableRow>
                  {
                     envelope?.status === 'Completed' ?
                        (
                           <TableRow>
                              <TableCell>Completed On</TableCell>
                              <TableCell>{envelope?.completedDate ? moment(envelope?.completedDate).format("MMM Do, YYYY") : ''}</TableCell>
                           </TableRow>
                        ) :
                        (
                           <TableRow>
                              <TableCell>Sign Due Date</TableCell>
                              <TableCell>{envelope?.updatedOn ? moment(envelope?.updatedOn).add(90, 'days').format("MMM Do, YYYY") : ''}</TableCell>
                           </TableRow>
                        )
                  }

               </TableBody>
            </Table>
         </TableContainer>

         <List dense sx={{ pt: 4 }}>
            <ListItem disablePadding secondaryAction={<Avatar sx={{ width: 16, height: 16, bgcolor: green[600] }} variant="rounded"><Typography variant="caption">{envelope?.consentForms?.length >= 0 ? envelope.consentForms[0].consents.length : 0}</Typography></Avatar>}>
               <ListItemButton onClick={() => envelope.consentForms[0].consents.length === 0 ? null : navigate('consent-forms')}>
                  <ListItemIcon sx={{ minWidth: 24, mr: 1 }} >
                     <FontAwesomeIcon icon={faFileCircleCheck} className={"icon-fixed-width"} size="lg" color={theme.palette.primary.main} />
                  </ListItemIcon>
                  <ListItemText primary="Consent Forms" sx={{ opacity: 1 }} />
               </ListItemButton>
            </ListItem>
            <ListItem disablePadding secondaryAction={<Avatar sx={{ width: 16, height: 16, bgcolor: green[600] }} variant="rounded"><Typography variant="caption">{envelope?.documents?.length || 0}</Typography></Avatar>}>
               <ListItemButton onClick={() => navigate('documents')}>
                  <ListItemIcon sx={{ minWidth: 24, mr: 1 }} >
                     <FontAwesomeIcon icon={faFileContract} className={"icon-fixed-width"} size="lg" color={theme.palette.primary.main} />
                  </ListItemIcon>
                  <ListItemText primary="Documents" sx={{ opacity: 1 }} />
               </ListItemButton>
            </ListItem>
            <ListItem disablePadding secondaryAction={<Avatar sx={{ width: 16, height: 16, bgcolor: green[600] }} variant="rounded"><Typography variant="caption">{0}</Typography></Avatar>}>
               <ListItemButton onClick={() => null}>
                  <ListItemIcon sx={{ minWidth: 24, mr: 1 }} >
                     <FontAwesomeIcon icon={faPaperclip} className={"icon-fixed-width"} size="lg" color={theme.palette.primary.main} />
                  </ListItemIcon>
                  <ListItemText primary="Attachments" sx={{ opacity: 1 }} />
               </ListItemButton>
            </ListItem>
            {/* <ListItem disablePadding secondaryAction={<Avatar sx={{ width: 16, height: 16, bgcolor: green[600] }} variant="rounded"><Typography variant="caption">{envelope?.signerAttachments?.length || 0}</Typography></Avatar>}>
               <ListItemButton onClick={() => navigate('signer-attachments')}>
                  <ListItemIcon sx={{ minWidth: 24, mr: 1 }} >
                     <FontAwesomeIcon icon={faFileUpload} className={"icon-fixed-width"} size="lg" color={theme.palette.primary.main} />
                  </ListItemIcon>
                  <ListItemText primary="Signer Attachments" sx={{ opacity: 1 }} />
               </ListItemButton>
            </ListItem> */}
            <ListItem disablePadding secondaryAction={<Avatar sx={{ width: 16, height: 16, bgcolor: green[600] }} variant="rounded"><Typography variant="caption">{envelope?.recipients?.length + 1 || 0}</Typography></Avatar>}>
               <ListItemButton onClick={handlerClick}>
                  <ListItemIcon sx={{ minWidth: 24, mr: 1 }} >
                     <FontAwesomeIcon icon={faUsers} className={"icon-fixed-width"} size="lg" color={theme.palette.primary.main} />
                  </ListItemIcon>
                  <ListItemText primary="Recipients" sx={{ opacity: 1 }} />
               </ListItemButton>
            </ListItem>
         </List>

         <Box py={2}></Box>

         <Divider />

      </Box>
   );
}

const EnvelopeDrawer = ({ isSmallViewport, open, toggleDrawer }) => {
   const envelope = useSelector((state) => state.envelope.entity);

   return (
      <Drawer variant={isSmallViewport ? "temporary" : "permanent"} anchor="left" open={open}
         sx={{
            width: maxDrawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
               width: maxDrawerWidth,
               boxSizing: 'border-box',
               left: 'inherit'
            }
         }}>
         <Toolbar />
         <DrawerContent envelope={envelope} isSmallViewport={isSmallViewport} toggleDrawer={toggleDrawer} />
      </Drawer>
   );
};
export default EnvelopeDrawer;
