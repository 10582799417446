import { faCheck, faClock, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Paper } from '@mui/material';
import { Box, Chip, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { green, grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import InteractionStatus from '../../../components/interaction-status/InteractionStatus';
import ConsentForm from './ConsentForm';
import { selectLoggedInUserConsentForms, selectHasLoggedInUserConsentedAllForms } from './envelopeSlice';


function GetConsentStatusIcon({ status }) {
   if (status === 'Ready') {
      return <FontAwesomeIcon icon={faClock} size="xl" color={grey[500]} />
   }

   return (
      <FontAwesomeIcon icon={faCheck} size="xl" color={green[500]} />
   );
}

function SecondaryActionsList({ status, handleConsentFormSelectionClick }) {
   return (
      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2 }}>
         <InteractionStatus status={status} />
         <Button variant="outlined" color="success" startIcon={<FontAwesomeIcon icon={faSearch} />} sx={{ minWidth: 160 }} onClick={handleConsentFormSelectionClick}>Preview</Button>
      </Box>
   );
}

const ConsentFormsList = () => {
   const theme = useTheme();
   const [showConsentForm, setShowConsentForm] = useState(false);
   const [selectedConsentForm, setSelectedConsentForm] = useState(null);

   const consentForms = useSelector(selectLoggedInUserConsentForms);
   const hasConsentedAllForms = useSelector(selectHasLoggedInUserConsentedAllForms);

   if (!consentForms || consentForms.length === 0) {
      return <></>;
   }

   const handleConsentFormSelection = cf => {
      setSelectedConsentForm(cf);
      setShowConsentForm(true);
   }

   const handleConsent = async status => {
      // TODO: Dispatch action here to accept/reject consent on the sever.
      setShowConsentForm(false);
   }

   return (
      <Paper sx={{ p: 2 }}>
         <Typography sx={{ color: "text.secondary", fontSize: 12, fontWeight: 700 }}>CONSENT FORMS </Typography>
         {
            hasConsentedAllForms ?
               <Typography variant="body2" sx={{ py: 1 }}>You have {consentForms.length} consent form(s) assigned to you. Consent forms need to be reviewed and accepted prior
                  to signing any document(s).
               </Typography> :
               <Alert severity="warning" sx={{ mt: 1 }}>You have {consentForms.length} consent form(s) assigned to you. Consent forms need to be reviewed and accepted prior
                  to signing any document(s).</Alert>
         }

         <List dense>
            {
               consentForms.map(c => (
                  <ListItem key={c.id} disablePadding secondaryAction={<SecondaryActionsList status={c.consent?.statusName} handleConsentFormSelectionClick={() => handleConsentFormSelection(c)} />}>
                     <ListItemButton onClick={() => handleConsentFormSelection(c)}>
                        <ListItemIcon sx={{ minWidth: 24, mr: 1 }} >
                           <GetConsentStatusIcon status={c.consent?.statusName} />
                        </ListItemIcon>
                        <ListItemText primary={c.title} sx={{ fontWeight: 'bold' }} secondary={c.description} />
                     </ListItemButton>
                  </ListItem>))
            }
         </List>
         {showConsentForm && <ConsentForm form={selectedConsentForm} consentHandler={handleConsent} />}
      </Paper>
   );
}

export default ConsentFormsList;
