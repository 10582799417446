import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import React from 'react';
import { getInteractionStatusColor, getInteractionStatusDescription, getInteractionStatusIcon } from './interactionStatusUtils';

const InteractionStatusIcon = ({ status, sx, iconSize = 'small' }) => {
    return (
        <Tooltip title={getInteractionStatusDescription(status)}>
            <FontAwesomeIcon icon={getInteractionStatusIcon(status)} size="xl" color={getInteractionStatusColor(status)} />
        </Tooltip>
    )
}

export default InteractionStatusIcon;
