import { AuthenticatedTemplate } from '@azure/msal-react';
import { faEnvelopeOpenText, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Paper } from '@mui/material';
import React, { useState } from 'react';
import { PageTitle } from '../../components/layout/PageTitle';
import AddEditAttachmentsList from '../../features/signature/add-edit-envelope/attachments/AddEditAttachmentsList';
import AddEditConsentFormsList from './../../features/signature/add-edit-envelope/consent-forms/AddEditConsentFormsList';
import AddEditDocumentsList from './../../features/signature/add-edit-envelope/documents/AddEditDocumentsList';
import EnvelopeInformation from './../../features/signature/add-edit-envelope/envelope-information/EnvelopeInformation';

const AddEditEnvelope = () => {
    
    return (
        <AuthenticatedTemplate>
            <Box px={3} pb={3} pt={2}>
                <PageTitle title="Create New Envelope" faIcon={faEnvelopeOpenText} helperText="Please add atleast one recipient and document in the envelope."
                    rightAction={<Button variant="contained" color="primary" startIcon={<FontAwesomeIcon icon={faSave} />} onClick={() => { }}>Save</Button>}
                />
               <EnvelopeInformation />

                <AddEditConsentFormsList />

                <AddEditDocumentsList />

                <AddEditAttachmentsList />
            </Box>
        </AuthenticatedTemplate>
    );
};
export default AddEditEnvelope;
