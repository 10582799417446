import { red } from '@mui/material/colors';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

// Create a theme instance.
let baseTheme = createTheme({
    palette: {
        primary: {
            main: '#2F5480', //#355C7D: blue
            light: '#26aae1'
        },
        secondary: {
            main: '#ff3399', //ff3399: pink, ff9800: orange, F8D164: S9
            contrastText: '#ffffff'
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#f2f2f2'
        },
    },
    typography: {
        fontFamily: [
            'Montserrat',
            'sans-serif',
        ].join(','),
        fontSize: 12
    }
});

export const theme = responsiveFontSizes(baseTheme);