import { faDownload, faInfo, faSignature, faPenClip, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { recipientCard, selectHasLoggedInUserConsentedAllForms } from './envelopeSlice';

const AttachmentsList = () => {
   const theme = useTheme();
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const attachments = useSelector((state) => state.envelope.entity?.attachments || []);
   const hasConsentedAllForms = useSelector(selectHasLoggedInUserConsentedAllForms);

   useEffect(() => {
      dispatch(recipientCard({ payload: false }))
      return () => false
   }, [])

   if (!attachments || attachments.length === 0) {
      return <></>;
   }

   const openAttachment = d => {
      navigate(`documents/${d.id}`)
   }

   return (
      <Paper sx={{ p: 2 }}>
         <Typography sx={{ color: "text.secondary", fontSize: 12, fontWeight: 700 }}>ATTACHMENTS</Typography>
         <Typography variant="body2" sx={{ py: 1 }}>Below attachments are included in the envelope for your reference.</Typography>

         <List dense>
            {
               attachments.map(c => (
                  <ListItem key={c.id} disablePadding secondaryAction={
                     <Button variant="outlined" color="info" sx={{ minWidth: 160 }}
                        startIcon={<FontAwesomeIcon icon={faDownload} />} disabled={!hasConsentedAllForms}> DOWNLOAD</Button>
                  }>
                     <ListItemButton onClick={() => openAttachment(c)} disabled={!hasConsentedAllForms}>
                        <ListItemIcon sx={{ minWidth: 24, mr: 1 }} >
                           <FontAwesomeIcon icon={faPaperclip} size="xl" color={blue[400]} />
                        </ListItemIcon>
                        <ListItemText primary={c.title} sx={{ fontWeight: 'bold' }} secondary={c.description} />
                     </ListItemButton>
                  </ListItem>))
            }
         </List>
      </Paper>
   );
}

export default AttachmentsList;
