import { faCheckCircle, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse } from '@mui/material';
import { Box, Checkbox, Divider, FormControlLabel, FormGroup, Grid, Paper, TextField, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';

const initInfo = {
    title: '',
    description: '',
    emailSubject: '',
    emailBody: ''
};

const EnvelopeInformation = () => {
    const theme = useTheme();

    const [data, setData] = useState(initInfo);
    const [emailSameAsTitle, setEmailSameAsTitle] = useState(data.title === data.emailSubject && data.description === data.emailBody);

    const [informationStatus, setInformationStatus] = useState('default');
    useEffect(() => {
        if (!data.title || !data.description || (emailSameAsTitle && (!data.emailSubject || !data.emailBody))) {
            setInformationStatus('warning');
        } else {
            setInformationStatus('success');
        }
    }, [data]);

    useEffect(() => {
        if (emailSameAsTitle) {
            setData({
                ...data,
                emailSubject: data.title,
                emailBody: data.description
            })
        }
    }, [emailSameAsTitle])

    const handleTitleChange = e => {
        setData({
            ...data,
            title: e.target.value,
            emailSubject: emailSameAsTitle ? e.target.value : data.emailSubject
        })
    }

    const handleDescriptionChange = e => {
        setData({
            ...data,
            description: e.target.value,
            emailBody: emailSameAsTitle ? e.target.value : data.emailBody
        })
    }

    const handleEmailSubjectChange = e => {
        setData({
            ...data,
            emailSubject: e.target.value
        })
    }

    const handleEmailBodyChange = e => {
        setData({
            ...data,
            emailBody: e.target.value
        })
    }

    const toggleEmailSameAsTitle = e => {
        setEmailSameAsTitle(!emailSameAsTitle);
    }

    return (
        <Paper sx={{ my: 3, p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2 }}>
                <FontAwesomeIcon icon={informationStatus === 'success' ? faCheckCircle : faWarning}
                    color={informationStatus === 'success' ? theme.palette.success.main : theme.palette.warning.main} fontSize={18} />
                <Typography variant="subtitle2">ENVELOPE INFORMATION</Typography>
            </Box>

            <Divider sx={{ my: 1 }} />
            <Grid container columnSpacing={4} rowSpacing={2} sx={{ my: 1 }}>
                <Grid item xs={4}>
                    <TextField id="envelope-title" label="Title" variant="outlined" fullWidth value={data.title} onChange={handleTitleChange} />
                </Grid>
                <Grid item xs={8}>
                    <TextField id="envelope-description" label="Description" variant="outlined" fullWidth value={data.description} onChange={handleDescriptionChange} />
                </Grid>
                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={emailSameAsTitle} onChange={toggleEmailSameAsTitle} />} label="Keep email subject & body same as title & description." />
                    </FormGroup>

                </Grid>
                <Grid item xs={12}>
                    <Collapse in={!emailSameAsTitle}>
                        <Grid container columnSpacing={4} rowSpacing={1}>
                            <Grid item xs={4}>
                                <TextField id="email-subject" label="Email Subject" variant="outlined" value={data.emailSubject} onChange={handleEmailSubjectChange} fullWidth />
                            </Grid>
                            <Grid item xs={8}>
                                <TextField id="email-body" label="Email Body" variant="outlined" value={data.emailBody} onChange={handleEmailBodyChange} fullWidth />
                            </Grid>
                        </Grid>
                    </Collapse>
                </Grid>
            </Grid>
        </Paper>
    );
};
export default EnvelopeInformation;
