import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { getInboxEnvelopes } from './../../../api/envelopeApi';

const inboxAdapter = createEntityAdapter();
const initialState = inboxAdapter.getInitialState({
    status: 'idle',
    error: null
});

// Thunk functions
export const refreshInbox = createAsyncThunk('inbox/refresh', async () => {
    const response = await getInboxEnvelopes();
    return response;
})

const inboxSlice = createSlice({
    name: 'inbox',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(refreshInbox.pending, (state, action) => {
                state.status = 'pending';
                state.error = null;
            })
            .addCase(refreshInbox.fulfilled, (state, action) => {
                inboxAdapter.setAll(state, action.payload);
                state.status = 'success';
                state.error = null;
            })
            .addCase(refreshInbox.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error.message;
            })
    }
});

export default inboxSlice.reducer;

export const { selectAll, selectById } = inboxAdapter.getSelectors(state => state.inbox);