import { Add, Cancel, Refresh, Save } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, Grid, TextField, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import InteractionStatus from '../../../../components/interaction-status/InteractionStatus';

const initData = {
    title: '',
    description: '',
    status: 'Draft',
    fileName: '',
    file: null
};

const AddEditArtifact = ({ artifact, saveHandler, open=false }) => {

    const [data, setData] = useState(initData);

    useEffect(() => {
        setData(artifact)
    }, [artifact])

    const handleDataUpdate = (key, value) => {
        const updatedData = { ...data };
        updatedData[key] = value;

        if (key === 'file') {
            updatedData.fileName = value.name;
        }

        setData(updatedData);
    }

    const handleFileSelection = e => {
        const files = Array.from(e.target.files);
        const file = files[0];

        handleDataUpdate('file', file);
    }

    const handleReset = () => {
        setData(initData);
    }

    const handleSave = () => {
        saveHandler(data);
    }

    return (
        <Dialog open={open} fullWidth={true} maxWidth="md">
            <DialogTitle>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="h6">{data.title}</Typography>
                    <InteractionStatus status={data.status} sx={{ color: "grey" }} />
                </Box>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Edit Form
                </DialogContentText>

                <Grid container columnSpacing={4} rowSpacing={3} sx={{ my: 1 }}>
                    <Grid item xs={12}>
                        <TextField id="envelope-title" label="Title" variant="outlined" fullWidth value={data.title} onChange={(e) => handleDataUpdate('title', e.target.value) } />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="envelope-description" label="Description" variant="outlined" fullWidth value={data.description} onChange={(e) => handleDataUpdate('description', e.target.value) } />
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2 }}>
                            <Typography>File: </Typography>
                            <Typography component="span" color="primary" sx={{ fontWeight: 'bold' }}>{data.fileName}</Typography>
                            <label htmlFor="upload-file">
                                <input
                                    style={{ display: 'none' }}
                                    id="upload-file"
                                    name="upload-file"
                                    type="file"
                                    onChange={handleFileSelection}
                                />
                                <Fab
                                    color="secondary"
                                    size="small"
                                    component="span"
                                    aria-label="upload"
                                    variant="extended"
                                >
                                    {!data.fileName ? <><Add /> Upload File</> : <><Refresh /> Replace File</>}
                                </Fab>
                            </label>
                        </Box>
                        
                    </Grid>
                    
                </Grid>

            </DialogContent>
            <DialogActions sx={{ pb: 3 }}>
                <Button variant="outlined" color="error" startIcon={<Cancel />} onClick={handleSave}>Cancel</Button>
                <Button variant="outlined" startIcon={<Refresh />} onClick={handleReset}>Reset</Button>
                <Button variant="contained" startIcon={<Save /> } onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};
export default AddEditArtifact;
