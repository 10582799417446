import { Box } from "@mui/material";
import React from 'react';
import './BoxShuffleLoader.Module.css';

const BoxShuffleLoader = ({ sx }) => {

    return (
        <Box sx={{ ...sx }}>
            <div className="container">
                <div className="holder">
                    <div className="box"></div>
                </div>
                <div className="holder">
                    <div className="box"></div>
                </div>
                <div className="holder">
                    <div className="box"></div>
                </div>
            </div>
        </Box>
    );
};

export default BoxShuffleLoader;