import { Backdrop, CircularProgress, Grid, Typography } from '@mui/material';
import React from 'react';
import BoxShuffleLoader from '../loaders/BoxShuffle/BoxShuffleLoader';
import LoopBikeLoader from '../loaders/LoopBike/LoopBikeLoader';
import SoccerBallHexagonPatternLoader from '../loaders/SoccerBallHexagonPattern/SoccerBallHexagonPatternLoader';

function RandomLoader() {
    return <BoxShuffleLoader />;

    //const randomNumber = Math.round(Math.random() * 10);
    
    //switch (randomNumber) {
    //    case 0:
    //    case 1:
    //    case 2:
    //        return <LoopBikeLoader />;
    //    case 3:
    //    case 4:
    //    case 5:
    //        return <CircularProgress color="inherit" />;
    //    case 6:
    //    case 7:
    //    case 8:
    //        return <BoxShuffleLoader />;
    //    case 9:
    //    case 10:
    //        return <SoccerBallHexagonPatternLoader />;
    //    default:
    //        return <CircularProgress color="inherit" />;
    //}
}

const ProgressBackdrop = ({ open=true, initialBackgroundColor=false, label="Loading...." }) => {
    return (
        <Backdrop sx={{ color: '#fff', backgroundColor: initialBackgroundColor ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.7)', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} >
            <Grid container direction="column" alignItems="center" rowSpacing={1}>
                <Grid item>
                    <RandomLoader />
                </Grid>
                <Grid item>
                    {/*<Typography component="div">{label}</Typography>*/}
                </Grid>
            </Grid>
        </Backdrop>
    )
}

export default ProgressBackdrop;
