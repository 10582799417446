import React from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import Document from '../features/signature/document/Document';
import AttachmentsList from '../features/signature/envelope/AttachmentsList';
import ConsentFormsList from '../features/signature/envelope/ConsentFormsList';
import Dashboard from '../features/signature/envelope/Dashboard';
import DocumentsList from '../features/signature/envelope/DocumentsList';
import Envelope from '../features/signature/envelope/Envelope';
import RecipientsInformation from '../features/signature/envelope/RecipientInformation';
import SignerAttachmentsList from '../features/signature/envelope/SignerAttachmentsList';
import AddEditEnvelope from '../pages/addEditEnvelope/AddEditEnvelope';
import LandingPage from '../pages/landing-page/LandingPage';
import Logout from '../pages/logout/Logout';
import Signature from '../pages/signature/Signature';
import Workspace from '../pages/workspace/Workspace';

function PageRouting() {

   return (
      <Routes>
         <Route path="/" element={<LandingPage />} />
         <Route path="/logout" element={<Logout />} />
         <Route path="/esignature" element={<Signature />} />
         <Route path="/workspace" element={<Workspace />} />
         <Route path="/workspace/envelopes/:envelopeId" element={<AddEditEnvelope />} />
         <Route path="/envelopes/:id" element={<Envelope />}>
            <Route path="" element={<Dashboard />} />
            <Route path="consent-forms" element={<ConsentFormsList />} />
            <Route path="documents" element={<DocumentsList />} />
            <Route path="attachments" element={<AttachmentsList />} />
            <Route path="signer-attachments" element={<SignerAttachmentsList />} />
            {/* <Route path="recipients" element={<RecipientsInformation/>}/> */}
         </Route>

         <Route path="/envelopes/:id/documents/:docId" element={<Document />} />
      </Routes>
   )
}

export default PageRouting;