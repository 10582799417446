import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ArrowBackIos as ArrowBackIosIcon, HelpCenter as HelpIcon, Menu } from '@mui/icons-material';
import { Box, Divider, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React from 'react';
import { useNavigate } from "react-router-dom";

export const PageTitle = ({ title, faIcon, rightAction, helperText, tooltip, allowBack, chips, sx, spacing = 2, showMenuIcon = false, menuClickHandler }) => {
    const navigate = useNavigate();

    const navigateBack = () => {
        navigate(-1);
    }

    return (
        <Box sx={{ ...sx }}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Grid container spacing={{ xs: 1, md: spacing }} alignItems="center">
                        {
                            showMenuIcon && <Grid item>
                                <IconButton onClick={menuClickHandler}>
                                    <FontAwesomeIcon icon={faBars} />
                                </IconButton>
                            </Grid>
                        }
                        {
                            allowBack && <Grid item>
                                <IconButton onClick={navigateBack}>
                                    <ArrowBackIosIcon />
                                </IconButton>
                            </Grid>
                        }
                        <Grid item>
                            <Typography variant="h5" color="text.primary">
                                {/* color="#355c7d" */}
                                {faIcon && <FontAwesomeIcon icon={faIcon} />}
                                <Box component="span" pl={faIcon ? 2 : 0}>{title}</Box>
                            </Typography>
                        </Grid>
                        {
                            tooltip && <Grid item>
                                <Tooltip title={tooltip}>
                                    <IconButton>
                                        <HelpIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        }
                        {
                            chips?.map((c, i) => (
                                <Grid item key={i}>
                                    {{ ...c }}
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
                {
                    rightAction && <Grid item>{rightAction}</Grid>
                }
            </Grid>

            <Divider sx={{ marginTop: "0.625rem" }} />

            <Typography sx={{ fontSize: '11px', paddingTop: '4px' }} color="text.primary">
                {helperText}
            </Typography>
        </Box>
    );
};