import { FiberManualRecord } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { getInteractionStatusColor } from './interactionStatusUtils';

const InteractionStatus = ({ status, sx, iconSize = 'small' }) => {
    return (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FiberManualRecord fontSize={iconSize} sx={{ mr: 1, color: getInteractionStatusColor(status) }} />
                {status}
            </Box>
        </Box>
    )
}

export default InteractionStatus;
