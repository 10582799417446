import { faFileCircleCheck, faFileContract, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Card, CardActions, CardContent, Grid, Paper, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import RecipientsList from './RecipientsList';
import { Link as RouterLink, useParams } from "react-router-dom";
import RecipientsInformation from './RecipientInformation';
import { useDispatch, useSelector } from 'react-redux'
import { fetchValidate } from './envelopeSlice';

const Item = styled(Paper)(({ theme }) => ({
   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
   ...theme.typography.body2,
   padding: theme.spacing(2),
   borderTopStyle: 'solid',
   borderTopWidth: theme.spacing(0.5),
   borderTopColor: theme.palette.primary.light
}));

const WidgetCard = ({ icon, to, metric, subtitle, iconBackgroundColor, color, cardAction }) => {
   const theme = useTheme();

   return (
      <Card sx={{ minWidth: 212 }}>
         <CardContent sx={{ pt: 1, pb: 0 }}>
            <Box sx={{ display: "flex", columnGap: { xs: 1, md: 2, xl: 3 }, alignItems: "center", pt: 1 }}>
               <Box sx={{ display: 'flex', background: iconBackgroundColor, borderRadius: '50%', width: 40, height: 40, justifyContent: "center", alignItems: "center" }}>
                  <FontAwesomeIcon icon={icon} fontSize={20} color={color} />
               </Box>
               <Box>
                  <Typography variant="h4" color={color}>{metric}</Typography>
                  <Typography variant="subtitle2" color="text.secondary">{subtitle}</Typography>
               </Box>
            </Box>
         </CardContent>
         <CardActions sx={{ justifyContent: 'flex-end' }}>
            {cardAction}
         </CardActions>
      </Card>
   );
}

const Dashboard = ({ envelope }) => {
   const theme = useTheme();
   const dispatch = useDispatch()
   const cardHide = useSelector(state => state.envelope.cards);
   const consentCard = useSelector(state => state.envelope.entity);

   const { id } = useParams();

   useEffect(() => {
      (async () => await dispatch(fetchValidate(id)))();
   }, [])

   return (
      <Box>
         <Grid container columnSpacing={4}>
            <Grid item xs={4}>
               <WidgetCard icon={faFileCircleCheck} metric={consentCard?.consentForms ? consentCard.consentForms[0].consents.length : 0} subtitle="Consent Forms" iconBackgroundColor="#daeed1" color={theme.palette.success.light}
                  cardAction={<Button size="small" color="primary" component={RouterLink} to={consentCard.consentForms[0].consents.length === 0 ? null : 'consent-forms'} disabled={consentCard.consentForms[0].consents.length === 0 ? true : false}>See forms</Button>} />
            </Grid>
            <Grid item xs={4}>
               <WidgetCard icon={faFileContract} metric={1} subtitle="Documents" iconBackgroundColor="#cce5ef" color={theme.palette.primary.light}
                  cardAction={<Button size="small" color="primary" component={RouterLink} to={'documents'}>Sign documents</Button>} />
            </Grid>
            <Grid item xs={4}>
               <WidgetCard icon={faPaperclip} metric={0} subtitle="Attachments" iconBackgroundColor="#fbe3c0" color={theme.palette.warning.light}
                  cardAction={<Button size="small" color="primary" component={RouterLink} to={'attachments'} disabled={true}>See attachments</Button>} />
            </Grid>
            {/* <Grid item xs={3}>
                    <WidgetCard icon={faFileUpload} metric={1} subtitle="Signer Attachments" iconBackgroundColor="#efd6e3" color={theme.palette.secondary.light}
                        cardAction={<Button size="small" color="primary" component={RouterLink} to={'signer-attachments'}>Upload attachments</Button>} />
                </Grid> */}
         </Grid>
         <Box pt={3}>
            <RecipientsList />
            {/* {cardHide.payload ? <RecipientsInformation /> : <RecipientsList />} */}
         </Box>
      </Box>
   );
}

export default Dashboard;
