import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getEnvelope, getEnvelopeStatusCount } from './../../../api/envelopeApi';
import { msalInstance } from "./../../../index";
import { vaidateSignatureRequest } from '../../../api/documentApi';

const initialState = {
   entity: null,
   status: 'idle',
   error: null,
   cards: false,
   validateUser: {
      data: null,
      status: 'idle',
      error: null,
   },
   envStatusCount: {
      data: null,
      status: 'idle',
      error: null,
   }
};

// Thunk functions
export const fetchEnvelope = createAsyncThunk('envelope/fetch', async (envelopeId) => {
   const response = await getEnvelope(envelopeId);
   return response;
})
export const fetchValidate = createAsyncThunk('validate/fetch', async (envelopeId) => {
   const response = await vaidateSignatureRequest(envelopeId);
   return response;
})

export const fetchEnvelopeStatusCount = createAsyncThunk('envelopeStatus/fetch', async () => {
   const response = await getEnvelopeStatusCount();
   return response
})

const envelopeSlice = createSlice({
   name: 'envelope',
   initialState,
   reducers: {
      reset: (state, action) => {
         state = envelopeSlice.getInitialState();
      },
      recipientCard: (state, action) => {
         state.cards = action.payload
      }
   },
   extraReducers: builder => {
      builder.addCase(fetchEnvelope.pending, (state, action) => {
         state.status = 'loading';
         state.error = null;
      }).addCase(fetchEnvelope.fulfilled, (state, action) => {
         state.entity = action.payload;
         state.status = 'idle';
         state.error = null;
      }).addCase(fetchEnvelope.rejected, (state, action) => {
         state.status = 'idle';
         state.error = "An error occurred while fetching envelope. Please try again later." // TODO: Depending on error code, set message.
      })
      builder.addCase(fetchValidate.pending, (state, action) => {
         state.validateUser.status = 'loading';
         state.validateUser.error = null;
      }).addCase(fetchValidate.fulfilled, (state, action) => {
         state.validateUser.data = action.payload;
         state.validateUser.status = 'idle';
         state.validateUser.error = null;
      }).addCase(fetchValidate.rejected, (state, action) => {
         state.validateUser.status = 'idle';
         state.validateUser.error = "An error occurred while fetching envelope. Please try again later." // TODO: Depending on error code, set message.
      })
      builder.addCase(fetchEnvelopeStatusCount.pending, (state, action) => {
         state.envStatusCount.status = 'loading';
         state.envStatusCount.error = null;
      }).addCase(fetchEnvelopeStatusCount.fulfilled, (state, action) => {
         state.envStatusCount.data = action.payload;
         state.envStatusCount.status = 'idle';
         state.envStatusCount.error = null;
      }).addCase(fetchEnvelopeStatusCount.rejected, (state, action) => {
         state.envStatusCount.status = 'idle';
         state.envStatusCount.error = "An error occurred while fetching envelope. Please try again later." // TODO: Depending on error code, set message.
      })
   }
});

export const { reset, recipientCard } = envelopeSlice.actions;
export default envelopeSlice.reducer;

export const selectDocumentById = (state, documentId) => {
   //console.log(documentId, state);
   const document = state.envelope.entity?.documents.find(d => d.id == documentId);

   return document;
};

export const selectLoggedInUserConsentForms = (state) => {
   const recipient = state.envelope.entity.recipients.find(r => r.userIdentityGuid === msalInstance.getActiveAccount().localAccountId);
   const consentForms = state.envelope.entity.consentForms.map(cf => {
      return {
         ...cf,
         consent: cf.consents.find(c => c.recipientId === recipient.id)
      };
   });

   return consentForms;
}

export const selectHasLoggedInUserConsentedAllForms = (state) => {
   const recipient = state.envelope.entity.recipients.find(r => r.userIdentityGuid === msalInstance.getActiveAccount().localAccountId);
   const consents = state.envelope.entity.consentForms.map(cf => cf.consents.find(c => c.recipientId === recipient.id));

   if (!consents || consents.length === 0 || !consents[0]) return true;

   const hasConsentedAll = consents.some(c => c.statusName !== 'Ready')

   return hasConsentedAll;
}