import { AuthenticatedTemplate } from '@azure/msal-react';
import { Box } from '@mui/material';
import React from 'react';
import Inbox from '../../features/signature/inbox/Inbox';
import { styled, useTheme } from '@mui/material/styles';

const Signature = () => {
    const theme = useTheme();

    return (
        <AuthenticatedTemplate>
            <Box sx={{ height: 200, backgroundColor: theme.palette.primary.light }}>
            </Box>
            <Box px={3} pb={3} pt={2} sx={{ width: '75%', margin: 'auto' }}>
                <Inbox />
            </Box>
        </AuthenticatedTemplate>
    );
};
export default Signature;
