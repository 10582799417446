import { faAdd, faCheckCircle, faFilePen, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Edit } from '@mui/icons-material';
import { Alert, Box, Button, Collapse, Divider, Paper, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddEditArtifact from '../envelope-artifacts/AddEditArtifact';
import ArtifactsList from './../envelope-artifacts/ArtifactsList';



const AddEditDocumentsList = () => {
    const theme = useTheme();

    const [showNoDocumentAlert, setShowNoDocumentAlert] = useState(true);
    const [documents, setDocuments] = useState([]);

    const [openDocumentEditor, setOpenDocumentEditor] = useState(false);
    const [document, setDocument] = useState({});

    const [informationStatus, setInformationStatus] = useState('default');
    useEffect(() => {
        if (documents.length === 0) {
            setInformationStatus('warning');
            setShowNoDocumentAlert(true);
        } else {
            setInformationStatus('success');
            setShowNoDocumentAlert(false);
        }
    }, [documents]);

    const handleAddNewDocument = () => {
        setDocument({});
        setOpenDocumentEditor(true);
    }

    const handleEditDocument = (row) => {
        setDocument(row);
        setOpenDocumentEditor(true);
    }

    const handleEditorSave = row => {
        let data = { ...row };

        if (!data.id) {
            data = {
                id: Math.floor(Math.random() * 10),
                status: 'Draft',
                ...data
            };

            setDocuments([...documents, data]);
        } else {
            const index = documents.map(cf => cf.id).indexOf(data.id);
            let updatedDocuments = [...documents];
            updatedDocuments.splice(index, 1, data); //.filter(cf => cf.id !== data.id)

            setDocuments(updatedDocuments);
        }

        setOpenDocumentEditor(false);
    }

    const handlePrepareDocument = (row) => {
        console.log('handlePrepareDocument', row);
    }

    const extraColumns = [
        {
            field: 'prepare', headerName: 'Prepare', sortable: false, minWidth: 120, flex: 2,
            renderCell: (params) => {
                const prepareDocument = e => {
                    e.stopPropagation(); // Don't select this row after clicking
                    handlePrepareDocument(params.row);
                };

                return (
                    <Button color="warning" startIcon={<FontAwesomeIcon icon={faFilePen} />} size="small" onClick={prepareDocument}>Prepare</Button>
                );
            }
        }
    ];

    return (
        <Paper sx={{ my: 3, p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2 }}>
                    <FontAwesomeIcon icon={informationStatus === 'success' ? faCheckCircle : faWarning}
                        color={informationStatus === 'success' ? theme.palette.success.main : theme.palette.warning.main} fontSize={18} />
                    <Typography variant="subtitle2">DOCUMENTS</Typography>
                </Box>
                <Button variant="outlined" color="primary" size="small" onClick={handleAddNewDocument} startIcon={<FontAwesomeIcon icon={faAdd} />}>ADD NEW</Button>
            </Box>
            <Divider sx={{ mt: 1.5 }} />
            <Collapse in={showNoDocumentAlert} sx={{ my: showNoDocumentAlert ? 2 : 0 }}>
                <Alert severity="warning">
                    Atleast one document needs to be added in the envelope.
                </Alert>
            </Collapse>
            <ArtifactsList rows={documents} editHandler={handleEditDocument} extraColumns={extraColumns} />
            <AddEditArtifact open={openDocumentEditor} artifact={document} saveHandler={handleEditorSave} />
        </Paper>
    );
};
export default AddEditDocumentsList;
